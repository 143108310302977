<template>
  <div class="cost">
    <!-- <el-timeline>
      <el-timeline-item
        v-for="(item, index) in projectStageBelongList"
        :timestamp="item.planTypeName"
        icon="el-icon-more"
        placement="top"
        type="primary"
      > -->
    <template v-for="(item, index) in projectStageBelongList">
      <el-card :key="index">
        <!--  :disabled=" disabled || options.deptClassifyId !== item.deptClassifyId" -->

        <el-table
          :data="stageData[`${item.deptClassifyId}`]"
          row-key="uuId"
          border
          default-expand-all
          :row-class-name="tableRowClassName"
          @row-click="rowClick"
          :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
        >
          <el-table-column align="left" prop="serial" label="序号" width="100">
            <template slot-scope="scope">
              {{ scope.row.uuId | serial(scope.row.dataType, stageData[`${item.deptClassifyId}`]) }}
            </template>
          </el-table-column>

          <el-table-column label="任务名称" align="center">
            <template slot-scope="scope">
              <el-input
                :disabled="
                  scope.row.status == 20 ||
                  options.deptClassifyId !== item.deptClassifyId ||
                  disabled
                "
                v-if="scope.row.dataType == 'stage'"
                v-model="scope.row.stageName"
                placeholder="请输入"
                type="textarea"
                :autosize="{ minRows: 1, maxRows: 99 }"
              ></el-input>
              <el-input
                :disabled="
                  scope.row.status == 20 ||
                  options.deptClassifyId !== item.deptClassifyId ||
                  disabled
                "
                v-if="scope.row.dataType == 'task'"
                v-model="scope.row.taskName"
                placeholder="请输入"
                type="textarea"
                :autosize="{ minRows: 1, maxRows: 99 }"
              ></el-input>
            </template>
          </el-table-column>

          <el-table-column label="计划开始时间" align="center">
            <template slot-scope="scope">
              <el-date-picker
                ref="stageSdate"
                :disabled="
                  scope.row.status == 20 ||
                  options.deptClassifyId !== item.deptClassifyId ||
                  disabled
                "
                v-if="scope.row.dataType == 'stage'"
                value-format="timestamp"
                type="date"
                @change="stageStartTimeFn"
                v-model="scope.row.planStartDate"
                placeholder="请选择"
              ></el-date-picker>

              <el-date-picker
                :disabled="
                  scope.row.status == 20 ||
                  options.deptClassifyId !== item.deptClassifyId ||
                  disabled
                "
                v-if="scope.row.dataType == 'task'"
                value-format="timestamp"
                type="date"
                v-model="scope.row.taskStartDate"
                @change="taskStartTimeFn"
                @focus="taskStartFn(scope.row)"
                :picker-options="pickerOptionsTask0"
                placeholder="请选择"
              ></el-date-picker>
            </template>
          </el-table-column>

          <el-table-column label="计划结束时间" align="center">
            <template slot-scope="scope">
              <el-date-picker
                :disabled="
                  scope.row.status == 20 ||
                  options.deptClassifyId !== item.deptClassifyId ||
                  disabled
                "
                v-if="scope.row.dataType == 'stage'"
                value-format="timestamp"
                type="date"
                ref="stageEdate"
                v-model="scope.row.planEndDate"
                placeholder="请选择"
                @change="stageEndTimeFn"
                :picker-options="pickerOptionsStage"
              ></el-date-picker>
              <el-date-picker
                :disabled="
                  scope.row.status == 20 ||
                  options.deptClassifyId !== item.deptClassifyId ||
                  disabled
                "
                v-if="scope.row.dataType == 'task'"
                value-format="timestamp"
                type="date"
                v-model="scope.row.taskEndDate"
                placeholder="请选择"
                @focus="taskEndFn(scope.row)"
                :picker-options="pickerOptionsTask1"
              ></el-date-picker>
            </template>
          </el-table-column>
          <!--  :disabled=" disabled || options.deptClassifyId !== item.deptClassifyId" -->
          <el-table-column label="实际完成时间" align="center">
            <template slot-scope="scope">
              <el-date-picker
                :disabled="
                  scope.row.status == 20 ||
                  options.deptClassifyId !== item.deptClassifyId ||
                  disabled
                "
                value-format="timestamp"
                type="date"
                v-model="scope.row.accomplishDate"
                placeholder="请选择"
                @change="completeTimeFn"
              ></el-date-picker>
              <template v-if="scope.$index == completeIndex">
                <div v-if="completeTime" style="color: red; font-size: 12px">
                  * 请选择完成时间段
                </div>
              </template>
            </template>
          </el-table-column>

          <el-table-column label="任务内容" align="center">
            <template slot-scope="scope">
              <el-input
                :disabled="
                  scope.row.status == 20 ||
                  options.deptClassifyId !== item.deptClassifyId ||
                  disabled
                "
                v-model="scope.row.taskContent"
                type="textarea"
                :autosize="{ minRows: 1, maxRows: 99 }"
                placeholder="请输入"
              ></el-input>
            </template>
          </el-table-column>

          <el-table-column label="任务状态" width="110" align="center">
            <template slot-scope="scope">
              {{ scope.row.status | dict(dictData.projectStagePlanStatus) }}
            </template>
          </el-table-column>
          <el-table-column label="项目阶段进度（%）" align="center">
            <template slot-scope="scope">
              <el-input
                :disabled="
                  scope.row.status == 20 ||
                  options.deptClassifyId !== item.deptClassifyId ||
                  disabled
                "
                type="number"
                class="numrule"
                @blur="onProjectProgress(`${item.deptClassifyId}`, scope)"
                v-if="scope.row.dataType == 'stage'"
                v-model="scope.row.projectProgress"
                placeholder="请输入"
              ></el-input>
            </template>
          </el-table-column>
          <template v-if="options.isViewMonitoring">
            <el-table-column label="关联合同阶段" align="center">
              <template slot-scope="scope">
                <el-select
                  :disabled="
                    scope.row.status == 20 ||
                    options.deptClassifyId !== item.deptClassifyId ||
                    disabled
                  "
                  v-if="scope.row.dataType == 'stage'"
                  v-model="scope.row.agreementStagePlanId"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="n in originalForm.agreementStageList"
                    :label="n.stageName"
                    :value="n.id"
                    :key="n.id"
                  ></el-option>
                </el-select>
              </template>
            </el-table-column>
          </template>

          <el-table-column
            align="center"
            width="130"
            v-if="!(disabled || options.deptClassifyId !== item.deptClassifyId)"
          >
            <template slot="header" slot-scope="scope">
              <i
                v-if="projectInformation.status != 60"
                class="iconfont iconadd-circle"
                @click="addStage(`${item.deptClassifyId}`, item.planType)"
              ></i>
              <i
                v-if="options.status == item.status"
                class="iconfont iconsuccess"
                @click="stagePlanAccomplish(item)"
              ></i>
            </template>
            <template slot-scope="scope">
              <template v-if="scope.row.status == 10">
                <i
                  class="iconfont iconadd-circle"
                  v-if="scope.row.dataType == 'stage' && scope.row.status == 10"
                  @click="addTask(`${item.deptClassifyId}`, item.planType, scope)"
                ></i>
                <i
                  v-if="scope.row.status == 10"
                  class="iconfont iconerror"
                  @click="del(`${item.deptClassifyId}`, scope)"
                ></i>
                <i
                  v-if="scope.row.status == 10 && scope.row.id"
                  class="iconfont iconsuccess"
                  @click="complete(scope)"
                ></i>
              </template>
              <el-button v-else type="primary" plain size="small" @click="complete(scope, 1)"
                >撤回</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </el-card>
    </template>
    <!-- <div style="margin-top:8px;" v-if="options.isViewMonitoring">
      <span style="font-weight:900;color:black;">费用结算与付款方式</span>
      <el-card>
        <el-table :data="tableData" border style="width:100%">
          <el-table-column type="index" align="center" label="序号" width="90">
          </el-table-column>
          <el-table-column prop="stageName" align="center" label="阶段">
          </el-table-column>
          <el-table-column prop="paymentStandard" align="center" label="内容">
          </el-table-column>
          <el-table-column
            prop="paymentAmount"
            align="center"
            label="金额(元)"
            width="180"
          >
            <template slot-scope="scope">
              {{ scope.row.paymentAmount | thousands }}
            </template>
          </el-table-column>
          <el-table-column prop=" proceedsDate" label="付款时间" width="220">
            <template slot-scope="scope">
              <el-date-picker
                disabled
                value-format="timestamp"
                type="date"
                v-model="scope.row.proceedsDate"
                placeholder="请选择"
              ></el-date-picker
            ></template>
          </el-table-column>
        </el-table>
      </el-card>
    </div> -->
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { getUUID } from '@/util/common'
import { registerUpdateLifecycle } from 'echarts'
export default {
  components: {
    Dictionary: () => import('@/components/Dictionary.vue'),
  },
  props: {
    options: {
      type: Object,
      default: function () {
        return {}
      },
    },
  },
  data() {
    return {
      tableData: [],
      disabled: true,
      dictData: {
        projectStagePlanStatus: [],
      },
      completeIndex: null,
      completeTime: false,
      stageStartNum: null, //父级开始，开始结束
      stageEndNum: null, //子级开始，父级结束
      taskStartNum: null, //子级开始
      pickerOptionsStage: this.processDateStage(), //父级结束
      pickerOptionsTask1: this.processDateTask1(), //子级结束
      pickerOptionsTask0: this.processDateTask0(), //子级开始
    }
  },
  inject: ['refresh'],
  computed: {
    ...mapState({
      stageData: state => state.project.stageData,
      originalForm: state => state.project.originalForm,
      projectStageBelongList: state => state.project.projectStageBelongList,
      projectInformation: state => state.project.projectInformation,
    }),
  },
  watch: {
    originalForm: {
      immediate: true, //初始化立即执行
      deep: true, //对象深度监测
      handler: function (newVal) {
        if (newVal.agreementStageList) {
          this.tableData = newVal.agreementStageList.deepClone()
        }
        var res = newVal.deepClone()
        var stageData = {}

        try {
          if (res.projectStagePlanCollect) {
            res.projectStagePlanCollect.some(r => {
              let k = `${r.deptClassifyId}`
              if (!stageData[k]) {
                stageData[k] = []
              }
              let obj = r.combination({
                id: r.id,
                uuId: getUUID(),
                dataType: 'stage',
                children: [],
                // planType: r.planType,
              })
              res.projectStageTaskList.some(n => {
                if (n.projectStagePlanId == r.id) {
                  obj.children.push(
                    n.combination({
                      id: n.id,
                      uuId: getUUID(),
                      dataType: 'task',
                      // planType: r.planType,
                    })
                  )
                }
              })
              stageData[k].push(obj)
            })
          }
        } catch (e) {
          console.log(e)
        }
        this.$store.commit({
          type: 'project/SET_STAGEDATA',
          stageData: stageData,
        })
      },
    },
    options: {
      immediate: true, //初始化立即执行
      deep: true, //对象深度监测
      handler: function (options) {
        var deptIdList = this.$store.state.user.userInfo.deptIdList
        if (this.permission(['STAGEPLANEDIT'])) {
          if (
            options.operatingButton.some(r => {
              return r == 'EDIT'
            })
          ) {
            if (
              this.options.staffAttribute == 'LEADER' ||
              this.options.staffAttribute == 'MANAGER' ||
              this.options.staffAttribute == 'DEPTHEAD' ||
              this.options.staffAttribute == 'DEPT_MANAGER' ||
              this.options.staffAttribute == 'JH_MEMBER'
            ) {
              this.disabled = false
            }
          }
        }
      },
    },
  },
  filters: {
    serial(uuId, dataType, data) {
      var str = ''
      if (dataType == 'stage') {
        for (let i = 0; i < data.length; i++) {
          if (data[i].uuId == uuId) {
            str = i + 1
          }
        }
      } else if (dataType == 'task') {
        for (let i = 0; i < data.length; i++) {
          let item = data[i]
          for (let j = 0; j < item.children.length; j++) {
            if (item.children[j].uuId == uuId) {
              str = `${i + 1}.${j + 1}`
            }
          }
        }
      }

      return str
    },
    thousands(a) {
      if (a == null || a == '' || a == undefined) {
        return 0
      } else {
        a = Number(a).toLocaleString()

        return a
      }
    },
  },
  created() {
    this.$api.dict
      .listSysDictData('PROJECT_STAGE_PLAN_STATUS', true)
      .then(res => {
        this.dictData.projectStagePlanStatus = res.data
      })
      .catch(err => {
        console.log(err)
      })
  },
  methods: {
    onProjectProgress(key, scope) {
      scope.row.projectProgress = Number(Number(scope.row.projectProgress).toFixed(2))
      if (scope.row.projectProgress > 100) {
        scope.row.projectProgress = 100
        this.$message.warning('阶段进度最大只能100')
      }
      if (scope.row.projectProgress < 0) {
        scope.row.projectProgress = 0
        this.$message.warning('阶段进度不能为负数')
      }

      // this.stageData[key].forEach(v=>{

      // })
    },
    processDateStage() {
      //父级结束
      let self = this
      return {
        disabledDate(time) {
          if (self.stageStartNum > 0) {
            //如果开始时间不为空，则结束时间大于开始时间,且大于当前时间
            return new Date(self.stageStartNum).getTime() > time.getTime()
          } else {
            return time.getTime() < Date.now() - 1000 * 3600 * 24 //开始时间不选时，结束时间最大值大于等于当天
          }
        },
      }
    },
    processDateTask0() {
      //子级开始
      let self = this
      return {
        disabledDate(time) {
          if (self.stageStartNum > 0 || self.stageEndNum > 0) {
            //如果开始时间不为空，则结束时间大于开始时间,且大于当前时间
            return (
              new Date(self.stageStartNum).getTime() > time.getTime() ||
              new Date(self.stageEndNum).getTime() < time.getTime()
            )
          } else {
            return time.getTime() < Date.now() - 1000 * 3600 * 24 //开始时间不选时，结束时间最大值大于等于当天
          }
        },
      }
    },
    processDateTask1() {
      //子级结束
      let self = this
      return {
        disabledDate(time) {
          if (self.taskStartNum > 0 || self.stageEndNum > 0) {
            //如果开始时间不为空，则结束时间大于开始时间,且大于当前时间
            return (
              new Date(self.taskStartNum).getTime() > time.getTime() ||
              new Date(self.stageEndNum).getTime() < time.getTime()
            )
          } else {
            return time.getTime() < Date.now() - 1000 * 3600 * 24 //开始时间不选时，结束时间最大值大于等于当天
          }
        },
      }
    },
    taskEndFn(val) {
      if (val.taskplanStartDate) {
        this.stageStartNum = val.taskplanStartDate
        this.stageEndNum = val.taskplanEndDate
      }
    },
    taskStartFn(val) {
      if (val.taskplanStartDate) {
        this.stageStartNum = val.taskplanStartDate
        this.stageEndNum = val.taskplanEndDate
      }
    },
    taskStartTimeFn(val) {
      //子级开始
      this.taskStartNum = val
    },
    stageEndTimeFn(val) {
      //父级结束
      this.stageEndNum = val
    },
    stageStartTimeFn(val) {
      //父级开始
      this.stageStartNum = val
    },
    completeTimeFn(val) {
      this.completeTime = false
    },
    rowClick(row, column, event) {
      this.completeIndex = row.index
    },
    tableRowClassName({ row, rowIndex }) {
      row.index = rowIndex
    },
    complete(scope, index) {
      if (scope.row.accomplishDate == null) {
        this.completeIndex = scope.$index
        this.completeTime = true
        return
      } else {
        if (index != 1) {
          this.$confirm('确定完成该任务, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          })
            .then(() => {
              var k = 'stagePlanDetailAccomplish'
              if (scope.row.dataType == 'stage') {
                k = 'stagePlanDetailAccomplish'
                this.$api.project
                  .stagePlanDetailAccomplish({
                    accomplishDate: scope.row.accomplishDate,
                    agreementStagePlanId: scope.row.agreementStagePlanId,
                    planEndDate: scope.row.planEndDate,
                    planStartDate: scope.row.planStartDate,
                    projectStagePlanId: scope.row.id,
                    stageName: scope.row.stageName,
                    taskContent: scope.row.taskContent,
                    projectProgress: scope.row.projectProgress,
                  })
                  .then(res => {
                    this.$message.success('操作成功！')
                    //   this.refresh();
                    this.$emit('updateList')
                  })
                  .catch(err => {
                    console.log(err)
                  })
              } else {
                k = 'stageTaskAccomplish'

                this.$api.project
                  .stageTaskAccomplish({
                    accomplishDate: scope.row.accomplishDate,
                    stageTaskId: scope.row.id,
                    taskContent: scope.row.taskContent,
                    taskEndDate: scope.row.taskEndDate,
                    taskName: scope.row.taskName,
                    taskStartDate: scope.row.taskStartDate,
                  })
                  .then(res => {
                    this.$message.success('操作成功！')
                    //   this.refresh();
                    this.$emit('updateList')
                  })
                  .catch(err => {
                    console.log(err)
                  })
              }
              // this.$api.project[k]({
              //   projectStagePlanId: scope.row.id,
              //   stageTaskId: scope.row.id,
              //   accomplishDate: scope.row.accomplishDate,
              // })
              //   .then((res) => {
              //     this.$message.success("操作成功！");
              //     //   this.refresh();
              //     this.$emit("updateList");
              //   })
              //   .catch((err) => {
              //     console.log(err);
              //   });
            })
            .catch(e => {
              console.log(e)
            })
        } else {
          if (scope.row.dataType == 'stage') {
            this.$api.project
              .stagePlanDetailReject({
                projectStagePlanId: scope.row.id,
              })
              .then(res => {
                this.$message.success('操作成功！')
                //   this.refresh();
                this.$emit('updateList')
              })
              .catch(err => {
                console.log(err)
              })
          } else {
            this.$api.project
              .stageTaskReject({
                stageTaskId: scope.row.id,
              })
              .then(res => {
                this.$message.success('操作成功！')
                //   this.refresh();
                this.$emit('updateList')
              })
              .catch(err => {
                console.log(err)
              })
          }
        }
      }
    },
    stagePlanAccomplish(r) {
      this.$confirm('确定完成该任务, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.$api.project
            .stagePlanAccomplish({
              status: r.status,
              projectId: this.options.id,
            })
            .then(res => {
              this.$message.success('操作成功！')
              this.refresh()
              // this.$emit("updateList");
            })
            .catch(err => {
              console.log(err)
            })
        })
        .catch(() => {})
    },
    addStage(key, planType) {
      if (!this.stageData[key]) {
        this.stageData[key] = []
      }
      this.stageData[key].push({
        uuId: getUUID(),
        dataType: 'stage',
        children: [],
        planType,
        status: 10,
        agreementStagePlanId: null,
        projectProgress: null,
        taskContent: null,
        accomplishDate: null,
        planEndDate: null,
        planStartDate: null,
        stageName: null,
      })
      this.$forceUpdate()
    },
    addTask(key, planType, scope) {
      for (let i = 0; i < this.stageData[key].length; i++) {
        let r = this.stageData[key][i]
        if (r.uuId == scope.row.uuId) {
          r.children.push({
            uuId: getUUID(),
            dataType: 'task',
            planType,
            status: 10,
            taskplanStartDate: r.planStartDate,
            taskplanEndDate: r.planEndDate,
          })

          this.$set(this.stageData[key], i, r)
        }
      }
    },
    del(planType, scope) {
      var data = this.stageData[planType]
      var uuId = scope.row.uuId
      if (scope.row.dataType == 'stage') {
        for (let i = 0; i < data.length; i++) {
          if (data[i].uuId == uuId) {
            data.splice(i, 1)
          }
        }
      } else if (scope.row.dataType == 'task') {
        for (let i = 0; i < data.length; i++) {
          let item = data[i]
          for (let j = 0; j < item.children.length; j++) {
            if (item.children[j].uuId == uuId) {
              item.children.splice(j, 1)

              this.$set(this.stageData[planType], i, item)
              //this.$forceUpdate();
              // this.$store.commit({
              //     type: 'project/SET_STAGEDATA',
              //     stageData: this.stageData,
              // })
            }
          }
        }
      }
    },
  },
}
</script>
<style scoped lang="scss">
@import '@/styles/config.scss';
// input type为number，只输入数字
/deep/.numrule input::-webkit-outer-spin-button,
/deep/.numrule input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
/deep/.numrule input[type='number'] {
  -moz-appearance: textfield;
}
</style>
